import React, { useState } from "react";
import "./navbar.css";
import logo from "../../images/d3vs_logo_crop1.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo">D3VS</div>
        <div className={`nav-items ${isMenuOpen ? "active" : ""}`}>
          {/* <a href="/">Our Team</a> */}
          <a href="/portfolio">Portfolio</a>
          {/* <a href="/">Blog</a> */}
          <a href="/">Contact</a>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`bar ${isMenuOpen ? "open" : ""}`}></div>
          <div className={`bar ${isMenuOpen ? "open" : ""}`}></div>
          <div className={`bar ${isMenuOpen ? "open" : ""}`}></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
