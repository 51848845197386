import React from "react";
import "./pricing.css";
import { useForm, ValidationError } from "@formspree/react";

const Pricing = () => {
  const [state, handleSubmit] = useForm("mrgnqonn");
  if (state.succeeded) {
    return window.location.reload();
  }

  return (
    <div className="pricing-section">
      <h2 className="section-title">Pricing</h2>
      <div className="pricing-info">
        <p>Contact us for a customized quote.</p>
        <p>
          Please fill out the form below and we will get back to you as soon as
          possible.
        </p>
      </div>
      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <input placeholder="Name" id="name" type="name" name="name" />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
          <input placeholder="Email" id="email" type="email" name="email" />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
          <textarea placeholder="Message" id="message" name="message" />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          <button
            className="submit-button"
            type="submit"
            disabled={state.submitting}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Pricing;
