import React, { useState, useEffect } from "react";
import "./partners.css";
import PartnerLogo1 from "../../images/lamoral.png";
import PartnerLogo2 from "../../images/isy.png";
import PartnerLogo3 from "../../images/Yachtmates.png";

const logos = [PartnerLogo1, PartnerLogo2, PartnerLogo3];

const Partners = () => {
  return (
    <section className="partners-section">
      <h2>Some of our Customers</h2>
      <div className="pyramid">
        <div className="row top-row">
          <img src={PartnerLogo1} alt="Partner Logo 1" className="logo" />
        </div>
        <div className="row">
          <img src={PartnerLogo2} alt="Partner Logo 2" className="logo" />
          <img src={PartnerLogo3} alt="Partner Logo 2" className="logo" />
        </div>
        {/* <div className="row">
          <img src={PartnerLogo2} alt="Partner Logo 2" className="logo" />
          <img src={PartnerLogo2} alt="Partner Logo 2" className="logo" />
          <img src={PartnerLogo2} alt="Partner Logo 2" className="logo" />
        </div> */}
      </div>
    </section>
  );
};

export default Partners;
