import React from "react";
import "./ourWork.css";

const projects = [
  {
    id: 1,
    title: "Yachtmates",
    description:
      "This was a custom-made web application for a client in the boating sector. They offer a great cleaning service.",
    technologies: ["React", "JavaScript", "HTML/CSS", "Firebase"],
    image: "image.png",
  },
  {
    id: 2,
    title: "Lamoral Coatings",
    description:
      "A custom-made web application for a coating company located in Weert called Lamoral Coatings.",
    technologies: ["React", "JavaScript", "HTML/CSS", "Tailwind CSS"],
    image: "lamoral.png",
  },
  // {
  //   id: 3,
  //   title: "Isy2Connect",
  //   description:
  //     "Custom app that integrates with their application. Bundling with multiple environments merged into one app.",
  //   technologies: ["React Native", "CSS", "Laravel"],
  //   image: "isy.png",
  // },
  // {
  //   id: 4,
  //   title: "Strong discipline",
  //   description:
  //     "This was a custom-made web application for a client in the sports sector.",
  //   technologies: ["React", "JavaScript", "HTML/CSS", "UI/UX"],
  //   image: "gym.png",
  // },
];

const ProjectCard = ({ project }) => {
  return (
    <div className="project-card">
      <img src={project.image} alt={project.title} />
      <div className="project-details">
        <h2>{project.title}</h2>
        <p>{project.description}</p>
        <div className="technologies">
          {project.technologies.map((technology, index) => (
            <span key={index} className="technology-label">
              {technology}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

function Work() {
  return (
    <div className="portfolio">
      <h1 className="portfolio-header">Portfolio</h1>
      <div className="projects-grid">
        {projects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
}

export default Work;
