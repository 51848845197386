import React from "react";
import Pricing from "../pricing/index";
import Partners from "../partners/index";
import Contact from "../contact/index";
import "../home/home.css";
import heroImage from "../../images/softwaredevelopment.png";
import Offer from "../offer";
import Techstack from "../techstack";

const Home = () => {
  return (
    <div className="home-container">
      <div className="home">
        <div className="hero-section">
          <div className="hero-text">
            <h1>Custom-Built Software Solutions</h1>
            <p>
              Here, innovation starts with a conversation. We listen to your
              ideas, understand your needs, and then craft tailor-made software
              solutions that propel your business to new heights.
              <br />
            </p>
            <button className="contact-button">Contact us</button>
          </div>
          <div className="hero-image">
            <img src={heroImage} alt="Illustratie" />
          </div>
        </div>
      </div>

      <Offer />

      <Pricing />

      <Partners />

      <Techstack />

      <Contact />
    </div>
  );
};

export default Home;
