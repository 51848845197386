import React from "react";
import "./footer.css";
import d3vslogosmall from "../../styles/images/d3vsLogoSmall.png";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="socialMedia">
        <a
          href="https://www.instagram.com/officiald3vs/"
          target="_blank"
          rel="noopener noreferrer"
          className="socialIcon"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61556561041252"
          target="_blank"
          rel="noopener noreferrer"
          className="socialIcon"
        >
          <FaFacebook />
        </a>
        {/* <a
          href="https://twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          className="socialIcon"
        >
          <FaTwitter />
        </a> */}
        <a
          href="https://www.linkedin.com/company/d3vs/"
          target="_blank"
          rel="noopener noreferrer"
          className="socialIcon"
        >
          <FaLinkedin />
        </a>
      </div>
      <div className="txtLeft">
        <p className="titleFooterLeft">Company</p>
        <p className="txtLeftFooter">KVK: 91886104</p>
        <p className="txtLeftFooter">BTW: NL865805386B01</p>
      </div>
      <div className="txtLeft">
        <p className="titleFooterLeft">Legal</p>
        <a className="txtLeftFooter">Algemene voorwaarden (NL)</a>
        <a className="txtLeftFooter">Terms and conditions (ENG)</a>
        <a className="txtLeftFooter">Software & Services Agreement (ENG)</a>
        <p className="txtLeftFooter">Disclaimer (ENG)</p>
      </div>
      <div className="txtRight">
        <p className="titleFooterRight">Contact</p>
        <p className="txtLeftFooter">TEL: +316 23736565</p>
        <p className="txtLeftFooter">Sem.dewilde@d3vs.nl</p>
        <p className="txtLeftFooter">Frans Douvenstraat 19</p>
        <p className="txtLeftFooter">6041XR Roermond</p>
      </div>
      <p className="copyright">© 2024 d3vs B.V. All Rights Reserved.</p>
    </div>
  );
};

export default Footer;
