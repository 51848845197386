import React from "react";
import "./offer.css";

import webDevIcon from "../../images/code.png";
import mobileDevIcon from "../../images/coding.png";
import managedServicesIcon from "../../images/cloud-server.png";
import aiIntegrationIcon from "../../images/ai.png";

const Offer = () => {
  return (
    <div className="offerings-section">
      <h2 className="section-title">What we offer</h2>
      <div className="offering">
        <div
          className="icon"
          style={{ backgroundImage: `url(${webDevIcon})` }}
        ></div>
        <h3>Web Development</h3>
        <p>
          Leave a lasting impression on the web with our ability to craft
          high-end web applications that set the standard.
        </p>
      </div>
      <div className="offering">
        <div
          className="icon"
          style={{ backgroundImage: `url(${mobileDevIcon})` }}
        ></div>
        <h3>Mobile Development</h3>
        <p>
          In a world where smartphones and tablets dominate our daily routines,
          we wield the expertise to turn your app idea into a resounding
          success.
        </p>
      </div>
      <div className="offering">
        <div
          className="icon"
          style={{ backgroundImage: `url(${managedServicesIcon})` }}
        ></div>
        <h3>Hosting</h3>
        <p>
          As your hosting and support partner, we ensure optimal performance for
          your application, catering to your needs no matter the scale of your
          audience.
        </p>
      </div>
      <div className="offering">
        <div
          className="icon"
          style={{ backgroundImage: `url(${aiIntegrationIcon})` }}
        ></div>
        <h3>AI Integration</h3>
        <p>
          We leverage AI technology to enhance our services and offer tailored
          solutions to our clients, optimizing processes and delivering
          exceptional results.
        </p>
      </div>
    </div>
  );
};

export default Offer;
