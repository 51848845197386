import React from "react";
import "./contact.css";
import { useForm, ValidationError } from "@formspree/react";

const Contact = () => {
  const [state, handleSubmit] = useForm("xjvnwjbk");
  if (state.succeeded) {
    return window.location.reload();
  }

  return (
    <section className="contact-section">
      <div className="contact-container">
        <h2>Contact Us</h2>
        <p>Have questions or need to get in touch? We're here to help.</p>
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <input placeholder="Name" id="name" type="name" name="name" />
            <ValidationError prefix="Name" field="name" errors={state.errors} />
            <input placeholder="Email" id="email" type="email" name="email" />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            <input placeholder="Phone" id="phone" type="phone" name="phone" />
            <ValidationError
              prefix="Phone"
              field="phone"
              errors={state.errors}
            />
            <input
              placeholder="Company"
              id="company"
              type="company"
              name="company"
            />
            <ValidationError
              prefix="Company"
              field="company"
              errors={state.errors}
            />
            <textarea placeholder="Message" id="message" name="message" />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
            <button
              className="submit-button"
              type="submit"
              disabled={state.submitting}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
